import React from "react";
import PropTypes from "prop-types";
import appConstant from "../../common/constants/appConstant"

const Error = ({ tryAgain, cancel }) => {
    return (
        <div className="card-custom error">
            <div className="card-header-custom">
                <div className="close-btn" onClick={cancel}></div>
            </div>
            <div className="card-body-custom">
                <div className="error-content">
                    <div className="avatar"></div>
                    <div className="err-message">{appConstant.errMessage}</div>
                </div>
            </div>

            <div className="card-footer-custom align-mid">
                <button type="button" onClick={() => tryAgain()} className="button-pri">{appConstant.tryAgain}</button>
                <button type="button" onClick={() => cancel()} className="button-sec">{appConstant.cancel}</button>
            </div>
        </div>
    )
}

Error.propTypes = {
    tryAgain: PropTypes.func,
    cancel: PropTypes.func,
}

export default Error;


