import React from "react";
import appConstant from "../../common/constants/appConstant";
const Loader = ({ loaderClass }) => {

    return (
        <div className={loaderClass}>
            <div className="pay-loader"></div>
            <span className="please-wait">{appConstant.pleaseWait}</span>
        </div>
    );
};

export default Loader;