import React, { useState, useEffect, useContext } from "react";
import PaymentDetails from "./PaymentDetails";
import appConstant from "../../common/constants/appConstant";
import SavedCardSelector from "./SavedCardSelector";
import Error from "../Error";
import { sendPostMessage } from "../../utilities/eventUtil";
import PaymentCardDetails from "./PaymentCardDetails";
import { cardDataManipulate } from "../PaymentModal/paymentHelper";
import PetPlanPayContext from "../../context/PetPlanPayContext";
import { sapiensChargeAPI, getWalletAPI } from "./service/paymentServices";
import Loader from "./Loader";

const Payment = () => {
  const cardDetails = {
    cardNumber: "",
    name: "",
    cardExpiry: "",
    cardCvc: "",
    zipCode: "",
  };

  const sdkConfig = useContext(PetPlanPayContext);
  const [cardDetailsData, setCardDetailsData] = useState(cardDetails);
  const [setStripCardData] = useState({});
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [cardList, setCardList] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cardOptions, setCardOptions] = useState([]);
  const [paymentKeys, setPaymentKeys] = useState({});
  const [walletId, setwalletId] = useState("");
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    setLoading(true);

    setCardDetailsData((prevState) => ({
      ...prevState,
      ...{ name: sdkConfig?.customerName, zipCode: sdkConfig?.zipCode },
    }));

    if (!sdkConfig?.externalReference?.id) return;

    getWalletAPI(sdkConfig)
      .then(function (data) {
        const hasResponse = Object.keys(data.payload.response).length;

        if (data.payload && hasResponse) {
          setCardOptions(cardDataManipulate(data.payload.response, sdkConfig)?.filter((item) => (item?.type === appConstant.PAYMENT_TYPE.BANK && item?.status === appConstant.BANK_ACCOUNT_STATUS.VERIFICATION_SUCCESSFUL) || item?.type === appConstant.PAYMENT_TYPE.CARD));
          setwalletId(data.payload.response.walletId);
        } else if (data.payload && !hasResponse) {
          setCardList(false);
        } else {
          setCardList(false);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sdkConfig]);

  const cancel = () => {
    setCardDetailsData({});
    closeModal();
  };

  const tryAgain = () => {
    setError(false);
  };

  const addNewCard = () => {
    setCardList(false);
  };

  const handleOnChnage = (value) => {
    setSelectedOption(value);
  };

  const closeModal = () => {
    sendPostMessage("payment-cancelled");
  };

  const getSelectedOptionStrategy = (cardOptions = []) => {
    if (selectedOption) return selectedOption;
    const preferredCard = cardOptions.find(card => card.isPreferred);
    switch (preferredCard.length) {
      case 0:
        return cardOptions[0].value;
      default:
        return preferredCard.value;
    }
  }

  const existingPayment = () => {
    
    //Existing payment code
    setInProgress(true);
    
    const payMethodId = getSelectedOptionStrategy(cardOptions);

    const resp = { payMethodId: payMethodId, walletId: walletId };

    sapiensChargeAPI(resp, sdkConfig, setError, false)
      .then((res) => {
        if (res) {
          const { response } = res && res.payload;
          if (response?.transaction?.transactionStatus?.status?.toUpperCase() === appConstant.approved || response?.transaction?.transactionStatus?.status?.toUpperCase() === appConstant.processing){
            sendPostMessage("payment-success", response);
          } else {
            sendPostMessage("payment-failed");
            setError(true);
          }
        } else {
          setInProgress(false);
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setInProgress(false);
      });
  };
  
  return (
    <div className="wrapper">
      {error ? (
        <Error tryAgain={tryAgain} cancel={cancel} />
      ) : (
        <div className="card-custom">
          {loading ? (
            <Loader loaderClass="loading" />
          ) : (
            <>
              <div className="card-header-custom">
                {appConstant.paymentInformation}
                <div className="close-btn" onClick={closeModal}></div>
              </div>
              <div className="card-body-custom">
                <PaymentDetails {...sdkConfig} />
                <hr />
                <h6 className="section-heading mar-btm">
                  {appConstant.cardDetails}
                </h6>
                {!cardList && (
                  <PaymentCardDetails
                    setCardDetailsData={setCardDetailsData}
                    cardDetailsData={cardDetailsData}
                    setStripCardData={setStripCardData}
                    paymentKeys={paymentKeys}
                    setPaymentKeys={setPaymentKeys}
                    setError={setError}
                    closeModal={closeModal}
                    sdkConfig={sdkConfig}
                  />
                )}
                {cardList && (
                  <SavedCardSelector
                    options={cardOptions}
                    handleOnChnage={handleOnChnage}
                    defaultValue={selectedOption}
                    addNewCard={addNewCard}
                  />
                )}
              </div>
              {cardList && (
                <div className="card-footer-custom">
                  <button
                    type="submit"
                    className={
                      inProgress
                        ? "button-pri align-rgt no-click"
                        : "button-pri align-rgt"
                    }
                    onClick={existingPayment}
                  >
                    {appConstant.makePayment}
                  </button>
                  <button
                    type="submit"
                    className="button-sec align-rgt"
                    onClick={cancel}
                  >
                    {appConstant.cancel}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Payment;
