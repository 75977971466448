import React, { useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardDetails from "./CardDetails";
import service from "../../services/http";
import restEndPointURLs from "../Payment/service/restEndPointURLs";
import { paymentIntentPayloadV1 } from "../../components/PaymentModal/paymentHelper";
import Loader from "./Loader";
const PaymentCardDetails = ({
  setCardDetailsData,
  cardDetailsData,
  setStripCardData,
  paymentKeys,
  setPaymentKeys,
  setError,
  closeModal,
  sdkConfig
}) => {
  //TODO:Add setup intent call here for load the stripe elements
  //  const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

  const stripePromise = useMemo(
    () =>
      service
        .post(
          `${restEndPointURLs.SETUP_INTENTv2.replace("{{countryCode}}", sdkConfig?.countryCode)}`,
          paymentIntentPayloadV1(sdkConfig)
        )
        .then(function (resp) {
          const { payload } = resp;
          if (payload) {
            const { publicKey, secret } =
              payload && payload.response && payload.response.keys;
            setPaymentKeys({ publicKey, secret });
            return loadStripe(publicKey);
          } else setError(true);
        }),
    [setError, setPaymentKeys, sdkConfig]
  );

  return (
    <Elements stripe={stripePromise}>
      {paymentKeys && !paymentKeys.publicKey ? <Loader loaderClass="loading-content" />
        : (
          <CardDetails
            setCardDetailsData={setCardDetailsData}
            cardDetailsData={cardDetailsData}
            setStripCardData={setStripCardData}
            paymentKeys={paymentKeys}
            setError={setError}
            closeModal={closeModal}
            sdkConfig={sdkConfig}
          />
        )}
    </Elements>
  );
};

export default PaymentCardDetails;
