import React, { useState, useEffect, useRef } from "react";
import appConstant from "../../common/constants/appConstant";
import { HandleBrandImage } from "helper/helper";

const SavedCardSelector = ({
  options,
  handleOnChnage,
  defaultValue,
  addNewCard,
}) => {
  const wrapperRef = useRef(null);
  const [selectOptions, setSelectOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue || "");
  const [selectedOptionLabel, setSelectedOptionLabel] = useState();
  const [defaultBrand, setDefaultBrand] = useState("")
  const handleExpandOptions = () => {
    setIsOpen((open) => !open);
  };

  const handleSelectOption = (e, value, label, brand="") => {
    e.stopPropagation();
    setSelectedOption(value);
    setSelectedOptionLabel(label);
    handleExpandOptions();
    handleOnChnage(value);
    setDefaultBrand(brand);
  };

  const setDefaultSelected = (cards) => {
    const defaultOption = cards.find((opt) => opt.isPreferred);
    if (defaultOption) {
      setSelectedOptionLabel(defaultOption.label);
      setSelectedOption(defaultOption.value);
      setDefaultBrand(defaultOption.brand);
    } else {
      setSelectedOptionLabel(cards[0].label);
      setSelectedOption(cards[0].value);
    }
  };

  useEffect(() => {
    setSelectOptions(options);
    if (options && options.length > 0) {
      if (defaultValue === "") {
        setDefaultSelected(options);
      } else {
        setSelectedOptionLabel(
          options.find((opt) => opt.value === defaultValue).label
        );
        setSelectedOption(
          options.find((opt) => opt.value === defaultValue).value
        );
      }
    }
    document.addEventListener("mouseup", handleClickOutside, false);
  }, [defaultValue, options]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const selectionDropDown = (selectOptions) => {
    return selectOptions.map((opt) => {
      const selValue = selectedOption === opt.value ? true : false;
      return (
        <li
          key={opt.value}
          className={selValue ? "is-active" : null}
          onChange={(e) => handleSelectOption(e, opt.value, opt.label, opt.brand)}
        >
          <label className="selection-option">
            <div className="payment-details-dropdown-details">
              <img src={HandleBrandImage(opt.brand)} height={20} width={28}/>
              <span>{opt.label}</span>
            </div>
            <input
              type="radio"
              id="valuePot"
              value=""
              name="Value Pot"
              defaultChecked={selValue}
            />
          </label>
        </li>
      );
    });
  };

  return (
    <div className="form-coupled">
      <label>{appConstant.paymentMethod}</label>
      <p className="instruction">{appConstant.instruction}</p>
      <div
        className="select-dropdown__wrapper"
        onClick={handleExpandOptions}
        ref={wrapperRef}
      >
        <div className="dropdown-field">
          <img src={HandleBrandImage(defaultBrand)} height={20} width={28}/>
          <p>{selectedOptionLabel}</p>
        </div>
        <label htmlFor="name">{appConstant.primaryPaymentMethod}</label>
        <div
          className={`select-dropdown__list-wrapper  ${
            isOpen ? "is-open" : ""
          }`}
        >
          <ul
            className="select-dropdown__list"
            role="listbox"
            aria-expanded={isOpen}
            aria-labelledby={`select-dropdown__label`}
          >
            {selectedOption && selectionDropDown(selectOptions)}
          </ul>
        </div>
        <div className={`dropdown-arrow ${isOpen ? "is-arrow" : ""}`}></div>
      </div>
      <button className="add-new-btn" onClick={() => addNewCard()}>
        <div className="add-icon"></div>
        <span className="addnew-txt">{appConstant.addNew}</span>
      </button>
    </div>
  );
};

export default SavedCardSelector;
