import React, { useState } from "react";
import PetPlanPayContext from "./context/PetPlanPayContext";
import { getPostMessage } from "./utilities/eventUtil";
import Payment from './components/Payment';

const App = () => {
    const [config, setConfig] = useState({});

    React.useEffect(() => {
        getPostMessage((data) => setConfig(data));
    }, []);
    
    return (
        <PetPlanPayContext.Provider value={config}>
            <Payment />
        </PetPlanPayContext.Provider>
    )
}

export default App;