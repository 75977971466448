import appConstant from "../../common/constants/appConstant";
import constants from "../../common/constants/appConstant";

export const cardDataManipulate = (customerCardDetails, sdkConfig) => {
  const data =
    (customerCardDetails &&
      customerCardDetails.pays &&
      Object.values(customerCardDetails.pays)) ||
    [];
  const standingInstructionPayIn =
    customerCardDetails && customerCardDetails.standingInstructionPayIn;

  const preferredCard = standingInstructionPayIn.find((value) => {
    const isCardForCurrentPolicy = sdkConfig.policyNumber ? value.policyNumber === sdkConfig.policyNumber : true;
    return isCardForCurrentPolicy && value.payRef[0].isPrimary === true;
  });
  return data.map((item) => ({
    label: `* ${item.card ? item.card.last4Digit : item.ach.last4Digit}`,
    value: item.payMethodId,
    isPreferred: getPreferredInfo(item, preferredCard),
    brand: item.card ? item.card.brand : appConstant.bankAccount,
    status: item?.status,
    type: item?.card ? appConstant.PAYMENT_TYPE.CARD : appConstant.PAYMENT_TYPE.BANK
  }));
};

export const getPreferredInfo = (card, preferredCard) => {
  return preferredCard &&
    preferredCard.payRef[0].payInMethodRef == card.payMethodId
    ? true
    : false;
};

//v1 payload
export const paymentIntentPayloadV1 = (sdkConfig) => {
  return {
    application: {
      name: "sales-funnel",
      identifier: "sales-funnel",
    },
    product: {
      type: "insurance",
      identifier: "Comprehensive_PetInsurance",
    },
    paymentType: "CARD",
    appId: constants.appId,
    externalReferenceId: sdkConfig?.externalReference?.id,
    externalReferenceType: sdkConfig?.externalReference?.type,
  };
};
