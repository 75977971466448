import { getEnvVar } from '../../../utilities/commonUtil';

const URL_PRE = getEnvVar("REACT_APP_ENV");

const restEndPointURLs = {
    ZIPCODE_VALIDATION: `fdp-lookup-service/v1/lookup/zip-code`,
    SETUP_INTENTv3: "fdp-wallet-service/v3/wallet/guest/payment-method",
    CONFIRM_INTENT: "fdp-wallet-service/v3/wallet/guest/payment-method/{{wallet-id}}",
    SETUP_INTENTv2: "fdp-wallet-service/v2/wallet/guest/payment-method?service-code={{countryCode}}",
    CONFIRM_INTENTv1: "fdp-wallet-service/v2/wallet/guest/payment-method/{{intentId}}?service-code={{countryCode}}",
    SAPIENS_CHARGE_API: "fdp-wallet-service/v2/wallet/create-pay-standing-instruction",
    GET_WALLET: "fdp-wallet-service/v2/wallet/wallet-lookup",
};

Object.keys(restEndPointURLs).map((key) => {
    restEndPointURLs[key] = `${URL_PRE}/${restEndPointURLs[key]}`;
    return restEndPointURLs[key];
});

export default { ...restEndPointURLs };
