import Axios from "axios";
import { DEFAULT_FETCH_CONFIG } from "../common/constants/defaultFetchConfig";
import { sendPostMessage, getPostMessage } from "../utilities/eventUtil";

function createAxios() {
    const axios = Axios.create(DEFAULT_FETCH_CONFIG);

    let sdkConfig = null;

    getPostMessage((data) => {
        sdkConfig = data;
    });

    const requestInterceptor = (config) => {
        if(!sdkConfig?.bearerToken) throw new axios.Cancel();
        
        config.headers.Authorization = `Bearer ${sdkConfig?.bearerToken}`;
        return config;
    }

    const responseInterceptor = (response) => {
        return response?.data;
    }

    const errorInterceptor = (error) => {
        sendPostMessage('payment-failed');
        return Promise.reject(error);
    }

    axios.interceptors.request.use(requestInterceptor);
    axios.interceptors.response.use(responseInterceptor, errorInterceptor);
    return axios;
}

// Initialise Axios
const api = createAxios();

const service = {
    get(route, options = {}) {
        return api.get(route, options);
    },
    post(route, payload = {}, options = {}) {
        return api.post(route, payload, options);
    },
    put(route, payload = {}, options = {}) {
        return api.put(route, payload, options);
    },
    patch(route, payload = {}, options = {}) {
        return api.patch(route, payload, options);
    },
};

export default service;