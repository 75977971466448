export default {
    currency: ['USD', 'CAD'],
    billingFrequency: ['Monthly', 'Quatarly', 'Yearly'],
    makePayment: "Make a Payment",
    cancel: "Cancel",
    paymentMethod: "Payment Method",
    instruction: "* Select to update the preferred payment method",
    primaryPaymentMethod: "Preferred Payment Method",
    addNew: "Add new Payment method",
    errMessage: "Sorry, we can’t complete your payment at this time",
    tryAgain: "Try Again",
    validationErrorMessage: "Please check the details and try again",
    setPrimaryPayment: "Set as preferred payment method",
    paymentInformation: "Payment Information",
    cardDetails: "Card Details",
    pleaseWait: "Please Wait...",
    appId: "sapiens1",
    bankAccount: "BANK",
    processing: "PROCESSING",
    approved: "APPROVED",
    BANK_ACCOUNT_STATUS: {
        VERIFICATION_PENDING: "VERIFICATION_PENDING",
        VERIFICATION_FAILED: "VERIFICATION_FAILED",
        VERIFICATION_SUCCESSFUL: "VERIFICATION_SUCCESSFUL"
    },
    PAYMENT_TYPE: {
        CARD: "CARD",
        BANK: "BANK"
    }
};
