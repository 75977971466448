import service from "../../../services/http";
import restEndPointURLs from "./restEndPointURLs";
import constants from "../../../common/constants/appConstant";

export const zipCodeApi = (zipcode) => {
  service.get(`${restEndPointURLs.ZIPCODE_VALIDATION}?zip=${zipcode}`);
};

export const confirmPayIntentApiV1 = (
  setupIntent,
  sdkConfig,
  setError,
  nameOnCard
) => {
  const confirmIntentPayload = {
    externalReferenceId: sdkConfig?.externalReference?.id,
    externalReferenceType: sdkConfig?.externalReference?.type,
    confirmSetupResponse: setupIntent,
    nameOnCard: nameOnCard.trim(),
    appId: constants.appId,
    application: {
      name: "sapiens",
      identifier: "sapiens",
    },
    product: {
      type: "insurance",
      identifier: "Comprehensive_PetInsurance",
    },
  };

  const path = restEndPointURLs.CONFIRM_INTENTv1.replace(
    "{{intentId}}",
    setupIntent.id
  ).replace("{{countryCode}}", sdkConfig.countryCode);

  return service.put(`${path}`, confirmIntentPayload);
};

export const sapiensChargeAPI = (
  resp,
  sdkConfig,
  setError,
  isPrimaryPayment
) => {
  const chargePaylaod = {
    annotatiions: {
      name: "string",
      attribute1: "value1",
    },
    appId: constants.appId,
    command: "CHARGE",
    externalReferenceId: sdkConfig?.externalTransactionId,
    partnerCode: sdkConfig?.partnerCode,
    money: {
      amount: sdkConfig.amount,
      currency: sdkConfig.currency,
    },
    paymentMethodId: resp.payMethodId,
    isUpdateSI: isPrimaryPayment,
    product: {
      category: "insurance",
      name: "Comprehensive_PetInsurance",
    },
    walletId: resp.walletId,
  };
  return service.post(`${restEndPointURLs.SAPIENS_CHARGE_API}`, chargePaylaod);
};

export const getWalletAPI = ({ externalReference: { id, type } }) => {
  return service.get(restEndPointURLs.GET_WALLET, {
    params: { externalReferenceId: id, externalReferenceType: type },
  });
};
