export const DEFAULT_FETCH_CONFIG = {
    timeout: 60 * 1000, // 1 min
    headers: {
        "Content-Type": "application/json",
        'Authorization': ''
    },
    async: true,
    cache: "no-store",
    max_age: 0,
    skip_headers: [],
};