import React, { useState } from "react";
import { zipCodeApi } from "./service/paymentServices";
import { isValidZipcode } from "../../utilities/commonUtil";
import CardDetailsForm from "./CardDetailsForm";

const CardDetails = ({
  setCardDetailsData,
  cardDetailsData,
  setStripCardData,
  paymentKeys,
  setError,
  closeModal,
  sdkConfig
}) => {
  const [iszipValid, setIsZipValid] = useState(false);
  const [zipCodeValue, setZipCodeValue] = useState("");

  const handleChange = (type, value) => {
    setCardDetailsData((prevDetails) => ({ ...prevDetails, [type]: value }));
  };

  const validateZipCode = (value) => {
    const isValidZip = isValidZipcode(value);
    setIsZipValid(!isValidZip);
    setZipCodeValue(value);
    if (value && isValidZip) {
      zipCodeApi(value);
    }
  };

  return (
    <CardDetailsForm
      setCardDetailsData={setCardDetailsData}
      cardDetailsData={cardDetailsData}
      handleChange={handleChange}
      validateZipCode={validateZipCode}
      iszipValid={iszipValid}
      zipCodeValue={zipCodeValue}
      setStripCardData={setStripCardData}
      paymentKeys={paymentKeys}
      setError={setError}
      closeModal={closeModal}
      sdkConfig={sdkConfig}
    />
  );
};

export default CardDetails;
