
//validate US/CA zipcode 
export const isValidZipcode = (value) => {
        const zipcode = value.toString().trim();
        const usZipPattern = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
        const caZipPattern = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i);
        const isValidZip =
            usZipPattern.test(zipcode.toString()) || caZipPattern.test(zipcode.toString().replace(/\W+/g, ""));
    return isValidZip;
};

export const transformCamelCase = (value) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
};

export const getEnvVar = (val) => {
  return window?._env_?.[val];
};