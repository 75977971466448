import React, { useEffect, useState, useCallback } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import appConstant from "../../common/constants/appConstant";
import { sendPostMessage } from "../../utilities/eventUtil";
import {
  confirmPayIntentApiV1,
  sapiensChargeAPI,
} from "./service/paymentServices";

let cardNumberElement = {};
let cardExpiryElement = {};
let cardCvcElement = {};

const CardDetailsForm = ({
  setCardDetailsData,
  cardDetailsData,
  handleChange,
  validateZipCode,
  iszipValid,
  zipCodeValue,
  setStripCardData,
  paymentKeys,
  setError,
  closeModal,
  sdkConfig,
  disableMakePaymentButton = true,
}) => {
  const elements = useElements();
  const stripe = useStripe();

  const [cardName, setCardName] = useState("");
  const [cardError, setCardError] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [cvvError, setCvvError] = useState(false);
  const [isPrimaryPayment, setPrimaryPayment] = useState(true);
  const [nameValue, isNameSet] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const mountCartDetails = useCallback(() => {
    const styleVar = {
      base: {
        fontFamily: "Inter, Arial, sans-serif",
        fontSize: "12px",
        height: "1.7em",
        color: "#142275",
        fontWeight: 500,
        "::placeholder": {
          fontWeight: "500",
          color: "#c1c5da",
          fontSize: "12px",
          fontFamily: "Inter, Arial, sans-serif",
        },
      },
    };
    cardNumberElement = elements.create("cardNumber", {
      placeholder: "Card Number",
      style: styleVar,
    });
    cardNumberElement.mount("#card-number");
    cardNumberElement.on("change", function (event) {
      if (event.complete) {
        // enable payment button
        setCardError(true);
      } else if (event.error) {
        // show validation to customer
        setCardError(false);
      } else if (event.empty) {
        setCardError(!event.empty);
      }
    });
    cardExpiryElement = elements.create("cardExpiry", {
      placeholder: "MM/YY",
      style: styleVar,
    });
    cardExpiryElement.mount("#expiration");
    cardExpiryElement.on("change", function (event) {
      if (event.complete) {
        // enable payment button
        setExpiryError(true);
      } else if (event.error) {
        // show validation to customer
        setExpiryError(false);
      } else if (event.empty) {
        setExpiryError(!event.empty);
      }
    });
    cardCvcElement = elements.create("cardCvc", {
      placeholder: "CVV",
      style: styleVar,
    });
    cardCvcElement.mount("#cvc");
    cardCvcElement.on("change", function (event) {
      if (event.complete) {
        // enable payment button
        setCvvError(true);
      } else if (event.error) {
        // show validation to customer
        setCvvError(false);
      } else if (event.empty) {
        setCvvError(!event.empty);
      }
    });
  }, [elements]);

  useEffect(() => {
    if (elements) mountCartDetails();
  }, [elements, mountCartDetails]);

  const initiatePayment = async () => {
    setInProgress(true);

    try {
      // create stripe token
      const tokenResult = await stripe.createToken(
        { ...cardNumberElement, ...cardExpiryElement, ...cardCvcElement },
        {
          address_zip: cardDetailsData.zipCode,
        }
      );

      // confirm setup intent
      // eslint-disable-next-line
      const setupIntent = await confirmCardSetup(paymentKeys.secret, {
        payment_method: {
          card: { token: tokenResult.token.id },
          billing_details: { name: cardName },
        },
      });

      if (setupIntent) {
        confirmPayIntentApiV1(setupIntent, sdkConfig, setError, cardName)
          .then(function (res) {
            // handle success
            const { response } = res.payload;

            sapiensChargeAPI(response, sdkConfig, setError, isPrimaryPayment).then((data) => {
              if (data) {
                const { response } = data && data.payload;
                if (
                  response?.transaction?.transactionStatus?.status ===
                  "Approved"
                ) {
                  sendPostMessage("payment-success", response);
                } else {
                  sendPostMessage("payment-failed");
                  setError(true);
                }
              } else setError(true);
            });
          })
          .catch(function (error) {
            setError(true);
          });
      }
    } catch (err) {
      setError(true);
    }
  };

  const confirmCardSetup = async (secretKey, Payload) => {
    try {
      const { setupIntent, error } = await stripe.confirmCardSetup(
        secretKey,
        Payload
      );
      if (error) return Promise.reject(error);
      return setupIntent;
    } catch (error) {
      setError(true);
    }
  };

  const setCardNumber = () => {
    setStripCardData({
      ...cardNumberElement,
      ...cardExpiryElement,
      ...cardCvcElement,
    });
  };

  const setName = (name) => {
    setCardName(name);
    if (name.trim() !== "") {
      isNameSet(true);
    } else isNameSet(false);
  };

  const onPrimaryPaymentChange = (event) => {
    const value = event.target.checked;
    setPrimaryPayment(value);
  };

  return (
    <>
      <div className="form-coupled no-btm-margin pay-form">
        <label htmlFor="cardNum">Card Number</label>
        <div
          name="CardNumber"
          required={true}
          value={cardDetailsData && cardDetailsData.cardNumber}
          id="card-number"
          className={"pay-textfield"}
          onChange={setCardNumber}
        />
      </div>

      {/* <div className="input-error-msg">
      <img src={ErrorIcon} className="error-icon"/>
      <span>Please check the details and try again</span></div> */}

      <div className="form-check-coupled">
        <input
          className="custom-form-check-input"
          type="checkbox"
          id="gridCheck"
          checked={isPrimaryPayment}
          onChange={onPrimaryPaymentChange}
        />
        <label className="custom-form-check-label" htmlFor="gridCheck">
          {appConstant.setPrimaryPayment}
        </label>
      </div>
      <div className="form-coupled pay-form">
        <label htmlFor="name">Name on Card</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          value={cardName}
          onChange={(e) => setName(e.target.value)}
          required={true}
          className="pay-textfield"
        />
      </div>

      <div className="form-coupled  width-50 mar-rgt pay-form pay-form">
        <label htmlFor="expiration">Expiration Date</label>
        <div
          name="Expiration"
          required={true}
          value={cardDetailsData && cardDetailsData.cardExpiry}
          id="expiration"
          className={"pay-textfield"}
        />
      </div>
      <div className="form-coupled width-50 pay-form">
        <label htmlFor="cvv">CVV</label>
        <div
          name="CVC"
          onChange={(e) => handleChange("cvv", e.target.value)}
          required={true}
          value={cardDetailsData && cardDetailsData.cardCvc}
          id="cvc"
          className="pay-textfield"
        />
      </div>
      <div className="form-coupled width-50">
        <label htmlFor="zipCode">Zip Code</label>
        <input
          type="text"
          id="zipCode"
          name="zipCode"
          placeholder="Zip Code"
          value={cardDetailsData && cardDetailsData.zipCode}
          onChange={(e) => handleChange("zipCode", e.target.value)}
          onBlur={(e) => validateZipCode(e.target.value)}
          required={true}
          maxLength={6}
          className="pay-textfield"
        />
        {iszipValid && zipCodeValue ? (
          <span className="input-error-msg">
            <span>Invalid Zipcode</span>
          </span>
        ) : null}
      </div>
      <div className="card-footer-custom new-card">
        <button
          id="make-payment-btn"
          type="submit"
          className={
            inProgress
              ? "button-pri align-rgt no-click"
              : "button-pri align-rgt"
          }
          onClick={initiatePayment}
          disabled={
            disableMakePaymentButton &&
            (!cardError ||
              !expiryError ||
              !cvvError ||
              iszipValid ||
              !nameValue)
          }
        >
          {appConstant.makePayment}
        </button>
        <button
          id="cancel-btn"
          type="submit"
          className="button-sec align-rgt"
          onClick={closeModal}
        >
          {appConstant.cancel}
        </button>
      </div>
    </>
  );
};

export default CardDetailsForm;
