import placeholder from '../assets/images/cardLogos/placeholder.png'
import amex from '../assets/images/cardLogos/Amex.png'
import diners from '../assets/images/cardLogos/DinersClub.png'
import discovery from '../assets/images/cardLogos/Discover.png'
import jcb from '../assets/images/cardLogos/JCB.png'
import mastercard from '../assets/images/cardLogos/Mastercard.png'
import unionPay from '../assets/images/cardLogos/UnionPay.png'
import visa from '../assets/images/cardLogos/Visa.png'
import bank from '../assets/images/bankIcon.svg'

export const HandleBrandImage = (value) => {
    switch (value) {
      case "VISA":
        return visa
      case "AMEX":
        return amex
      case "DINNERSCLUB":
        return diners
      case "DISCOVER":
        return discovery
      case "JCB":
        return jcb
      case "MASTERCARD":
        return mastercard
      case "UNIONPAY":
        return unionPay
      case "BANK":
        return bank
      default:
        return placeholder
    }
  }