import React from 'react'
import { transformCamelCase } from '../../utilities/commonUtil';

const PaymentDetails = ({ externalReference, policyEffectiveDate, paymentFrequency, currency, amount }) => {
    
    return (
        <>
            <div className="readonly-group">
                <h6>Customer ID</h6>
                <h5>{externalReference?.id}</h5>
            </div>

            <div className="readonly-group">
                <h6>Policy Effective Date</h6>
                <h5>{policyEffectiveDate}</h5>
            </div>

            <div className="readonly-group">
                <h6>Bill Frequency</h6>
                <h5>{transformCamelCase(paymentFrequency)}</h5>
            </div>

            <div className="readonly-group">
                <h6>Amount</h6>
                <h5><span className="amount">${amount}</span><span className="currency">{currency}</span></h5>
            </div>
        </>
    )
}

export default PaymentDetails;
